<template>
    <div class="items-center mx-auto mb-20" 
         role="timer"
         :class="{'lg:max-w-150 xl:max-w-none flex md:block md:text-center' : checkoutMode}">
        <div class="mb-10 w-120 max-w-full md:mx-auto"
             :class="[{'lg:w-full xl:w-200 mr-20  ml-auto': checkoutMode}, {'mx-auto lg:w-200': !checkoutMode}]">
            <div class="flex relative">
                <Watch/>
                <div class="absolute inset-0 m-auto w-[70%] h-[12.2rem] xl:w-[12rem]">
                    <WatchNoseGraphic class="mt-5 w-full h-full"
                                      :class="{'anim-nose-rotate': !timeIsUp}"/>
                </div>
            </div>
        </div>
        <div class="mr-auto font-fauli">
            <p class="mb-10 md:text-center lg:block lg:mx-auto"
               :class="[{'text-left md:hidden': checkoutMode}, {'text-center': !checkoutMode}]">
                <span v-if="checkoutMode"
                      class="inline-block max-w-170">{{ $translate('Checkout.Countdown.InfoText') }}</span>
                <span v-else>{{ $translate('Product.TooEarly.Announcement') }}</span>
            </p>
            <div class="flex md:justify-center">
                <div v-if="shouldHideDays">
                    <div class="relative whitespace-nowrap">
                        <span class="time">{{ days }}</span>
                        <CIcon v-for="item in days.length"
                               :key="item"
                               name="timer-1"
                               :fill="true"
                               class="w-29 h-32 text-white lg:w-24 xl:w-29 timer-icon"/>
                        <span class="time-text">
                            <span v-if="Number(days) > 1">{{ $translate('Checkout.Countdown.DayPlural') }}</span>
                            <span v-else>{{ $translate('Checkout.Countdown.Day') }}</span>
                        </span>
                    </div>
                </div>
                <CIcon v-if="shouldHideDays"
                       name="timer-spacer"
                       class="mx-5 mt-8 w-7 h-15 xl:mx-10"/>
                <div>
                    <div v-if="shouldHideHours"
                         class="relative whitespace-nowrap">
                        <span class="time">{{ hours }}</span>
                        <CIcon v-for="item in hours.length"
                               :key="item"
                               name="timer-1"
                               :fill="true"
                               class="w-29 h-32 text-white lg:w-24 xl:w-29 timer-icon"/>
                        <span class="time-text">
                            <span v-if="Number(hours) > 1">{{ $translate('Checkout.Countdown.HourPlural') }}</span>
                            <span v-else>{{ $translate('Checkout.Countdown.Hour') }}</span>
                        </span>
                    </div>
                </div>
                <CIcon v-if="shouldHideHours"
                       name="timer-spacer"
                       class="mx-5 mt-8 w-7 h-15 xl:mx-10"/>
                <div>
                    <div v-if="minutes"
                         class="relative whitespace-nowrap">
                        <span class="time">{{ minutes }}</span>
                        <CIcon v-for="item in minutes.length"
                               :key="item"
                               name="timer-1"
                               :fill="true"
                               class="w-29 h-32 text-white lg:w-24 xl:w-29 timer-icon"/>
                        <span class="time-text">{{ $translate('Checkout.Countdown.Minutes') }}</span>
                    </div>
                </div>
                <CIcon v-if="minutes"
                       name="timer-spacer"
                       class="mx-5 mt-8 w-7 h-15 xl:mx-10"/>
                <div>
                    <div v-if="seconds"
                         class="relative whitespace-nowrap">
                        <span class="time">{{ seconds }}</span>
                        <CIcon v-for="item in seconds.length"
                               :key="item"
                               name="timer-1"
                               :fill="true"
                               class="w-29 h-32 text-white lg:w-24 xl:w-29 timer-icon"/>
                        <span class="time-text">{{ $translate('Checkout.Countdown.Seconds') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <TimeIsUpOverlay v-if="timeIsUp"/>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, PropType, ref } from 'vue';
import Watch from '@/project/countdown/WatchGraphic.vue';
import WatchNoseGraphic from '@/project/countdown/WatchNoseGraphic.vue';
import TimeIsUpOverlay from '@/project/countdown/TimeIsUpOverlay.vue';
import { getTimeLeft } from '@/project/countdown/countdown.service';
import { ReservationStatusViewModel } from '@/api/checkout';
import { ClientMessage, ClientMessageType, ClientMessageWithError, GeneralErrorEventKey } from '@/core/messages/types';
import bus from '@/core/bus';
import dictionary from '@/core/dictionary/dictionary';

export default defineComponent({
    name: 'Countdown',
    components: {
        Watch,
        WatchNoseGraphic,
        TimeIsUpOverlay,
    },
    props: {
        checkoutMode: {
            default: true,
            type: Boolean,
            required: false,
        },
        startsIn: {
            default: null,
            type: Object as PropType<number | null>,
            required: false,
        },
    },
    setup(props) {
        let timeLeft;

        const seconds = ref<string>('');
        const minutes = ref<string>('');
        const hours = ref<string>('');
        const days = ref<string>('');
        const timeIsUp = ref(false);
        const reservationStatus = ref<ReservationStatusViewModel | null>(null);
        let timer;

        document.addEventListener('visibilitychange', handleVisibilityChange, false);

        // Handle browser window being minimized or idle
        function handleVisibilityChange() {
            if (document.visibilityState === 'visible' && props.checkoutMode) {
                clearInterval(timer);
                initTimer();
            }
        }

        onMounted(() => {
            initTimer();   
        });

        async function initTimer() {
            if (props.checkoutMode) {
                reservationStatus.value = await getTimeLeft();
                if (reservationStatus.value && reservationStatus.value?.notFound) {
                    handleReservationNotFound();
                    return;
                }
                timeLeft = reservationStatus.value?.msRemaining ?? 0;
            }
            else {
                timeLeft = props.startsIn;
            }
            showRemaining();
        }

        function showRemaining() {
            timer = setInterval(() => {
                if (timeLeft <= 0) {
                    if (props.checkoutMode) {
                        timeIsUp.value = true;
                    }
                    else {
                        window.location.reload();
                    }
                    clearInterval(timer);

                    return;
                }

                timeLeft = timeLeft - 1000;
                const timeInDays = Math.floor(timeLeft / asDays.value);
                const timeInHours = Math.floor((timeLeft % asDays.value) / asHours.value);
                const timeInMinutes = Math.floor((timeLeft % asHours.value) / asMinutes.value);
                const timeInSeconds = Math.floor((timeLeft % asMinutes.value) / asSeconds.value);

                seconds.value = formatTime(timeInSeconds);
                minutes.value = formatTime(timeInMinutes);
                hours.value = formatTime(timeInHours);
                days.value = formatTime(timeInDays);
            }, 1000);
        }

        const shouldHideDays = computed(() => {
            return parseInt(days.value);
        });

        const shouldHideHours = computed(() => {
            return parseInt(hours.value);
        });

        function formatTime(timeLeft) {
            if (timeLeft <= 0) {
                return '00';
            }
            return timeLeft < 10 ? `0${timeLeft}` : timeLeft.toString();
        }

        const asSeconds = computed(() => {
            return 1000;
        });

        const asMinutes = computed(() => {
            return asSeconds.value * 60;
        });

        const asHours = computed(() => {
            return asMinutes.value * 60;
        });

        const asDays = computed(() => {
            return asHours.value * 24;
        });

        function handleReservationNotFound() {
            const messages: ClientMessage[] = [{
                message: dictionary.get('Checkout.Countdown.NotFoundError'),
                messageType: ClientMessageType.Error,
                errorId: '',
                clearMessages: true,
            }];
            bus.emit(GeneralErrorEventKey, { messages, errorId: '' } as ClientMessageWithError);
        }

        onUnmounted(() => {
            clearInterval(timer);
            document.removeEventListener('visibilitychange', handleVisibilityChange, false);
        });

        return {
            seconds,
            minutes,
            hours,
            days,
            shouldHideDays,
            shouldHideHours,
            timeIsUp,
        };
    },
});
</script>

<style scoped>
.anim-nose-rotate {
    animation: rotate 5000ms infinite linear;
}

.time {
    margin-top: .6rem;
    margin-left: .8rem;
    letter-spacing: 1.8rem;
    @apply text-20 absolute mb-5;
}
.timer-icon {
    stroke: #000000;
}
.time-text {
    @apply block mt-5
}

.text-placement {
    margin-top: 45%;
    margin-left: 3.1rem;
    @apply absolute inset-y-0 font-fauli;
}

@screen lg {
    .time {
        margin-top: 5px;
        margin-left: .5rem;
        letter-spacing: 1.6rem;
        @apply text-18;
    }
}

@screen xl {
    .time {
        margin-top: .6rem;
        margin-left: .8rem;
        letter-spacing: 1.8rem;
        @apply text-20;
    }
}

@keyframes rotate {
  100% {
    transform: rotateZ(360deg);
  }
}
</style>
