<template>
    <RouterLink :to="model.url"
                class="block py-15 pr-20 hover:text-pink-100 outline-none transition-all duration-300 ease-in-out md:py-20 md:px-13 lg:p-20">
        {{ model.name }}
    </RouterLink>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'NavigationItem',
    components: {

    },
    props: {
        model: {
            type: Object as any,
            required: true,
        },
    },
});
</script>

<style scoped>
    .router-link-active {
        @apply text-pink-100;
    }
</style>
