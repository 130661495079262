<template>
    <Transition name="scale"
                mode="out-in">
        <button v-if="(!quantity || quantity < 0) && !hasFocus && !miniBasketMode"
                class="add-to-basket-btn"
                :class="{'opacity-50 pointer-events-none': disableLineItem}"
                @click="add()">
            <CIcon class="basket-icon"
                   :class="{'hidden': productTileMode}"
                   name="icon-basket"
                   fill/>
            <div class="mr-auto font-fauli text-16 text-white md:text-18"
                 :class="{'ml-auto': productTileMode}">
                {{ $translate('Product.AddToBasketText') }}
            </div>
            <CIcon class="add-to-basket-background"
                   :class="{'h-full w-full': productTileMode}"
                   name="btn-add-to-basket"
                   fill
                   preserveAspectRatio="none"/>
        </button>
        <div v-else
             ref="itemInBasketTarget"
             class="flex items-center h-44"
             :class="[{'-ml-12' : miniBasketMode}, {'flex-col': !miniBasketMode}]">
            <div class="flex relative items-center"
                 :class="{'opacity-50 pointer-events-none': disableLineItem}">
                <button class="flex relative items-center w-40 icon-hover"
                        :disabled="quantity <= 1 && miniBasketMode"
                        :aria-label="$translate('Accessibility.AddToBasket.DecreaseQuantityDownByOne')"
                        @click="decrement()">
                    <span class="button-inner"
                          :class="{'text-white': !miniBasketMode}">-</span>
                    <CIcon v-if="!miniBasketMode"
                           class="absolute inset-0 -z-1 w-40 h-35"
                           name="in-basket-button"
                           fill/>
                </button>
                <div class="relative w-[4.4rem] h-[3.8rem] text-center md:mx-5 md:w-60">
                    <form id="update-product-quantity"
                          name="update-product-quantity"
                          @submit.prevent="updateQuantity()">
                        <label for="quantityField"
                               class="sr-only">{{ $translate('Accessibility.AddToBasket.Quantity') }}: {{ quantity }}</label>
                        <input id="quantityField"
                               ref="updateQuantityField"
                               v-model.number="quantity"
                               type="number"
                               class="h-40 font-fauli text-19 text-center bg-transparent"
                               inputmode="numeric"
                               pattern="[0-9]*"
                               max="100"
                               min="0" 
                               @focus="hasFocus = true"
                               @blur="hasFocus = false; updateQuantity()">
                    </form>
                    <CIcon class="absolute inset-0 -z-1 w-full h-full"
                           name="in-basket-field"
                           original
                           preserveAspectRatio="none"/>
                </div>
                <button class="flex relative items-center w-40 icon-hover"
                        :aria-label="$translate('Accessibility.AddToBasket.IncreaseQuantityByOne')"
                        @click="increment()">
                    <span class="button-inner"
                          :class="{'text-white': !miniBasketMode}">+</span>
                    <CIcon v-if="!miniBasketMode"
                           class="absolute inset-0 -z-1 w-40 h-35 rotate-90"
                           name="in-basket-button"
                           fill/>
                </button>
                <PopoverWrap :show-popper="showMaxAllowedQuantity"
                             placement="top"
                             class="absolute translate-x-[-0.9rem] translate-y-[-10.3rem] md:translate-x-[0] xl:translate-x-[7rem] xl:translate-y-[-9.7rem]"
                             :class="{'translate-y-[-12rem] xl:translate-y-[-11.7rem]': miniBasketMode}">
                    <template #content>
                        <div class="relative">
                            <span class="block absolute z-11 mt-30 ml-10 w-[12.7rem] font-fauli text-13 xl:ml-40"
                                  v-html="$translate('Product.MaxQuantityAllowedText', maxAllowed+'')"></span>
                            <CIcon name="amount-popup"
                                   class="hidden absolute inset-0 w-180 h-[10.5rem] xl:block"
                                   original/>
                            <CIcon name="amount-popup-middle"
                                   class="absolute inset-0 w-[15.2] h-[10.8rem] xl:hidden"
                                   original/>
                        </div>
                    </template>
                </PopoverWrap>
            </div>
            <button v-if="miniBasketMode"
                    :aria-label="$translate('Accesibility.RemoveProduct')"
                    class="absolute top-0 right-0 mt-7 md:static md:mt-0 md:ml-40 transistion icon-hover"
                    @click="remove()">
                <CIcon class="w-20 h-auto"
                       name="icon-delete"
                       :title="$translate('Product.RemoveText')"
                       original/>
            </button>
        </div>
    </Transition>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, Ref, ref, watch } from 'vue';
import { addProduct, clientBasket, getAsyncBasketId, updateLineItem } from '@/project/basket/basket.service';
import { LineItemViewObject } from '@/api/checkout';
import { useRoute } from 'vue-router';
import PopoverWrap from '@/project/popover/PopoverWrap.vue';
import CIcon from '@/core/layout/icons/CIcon.vue';
import { onClickOutside, onKeyStroke } from '@vueuse/core';
import { CatalogProductTileViewObject, ProductDetailsModel } from '@/api/content';
import { TrackingObject } from '@/project/tracking/tracking.service';

export default defineComponent({
    name: 'AddToBasket',
    components: {
        PopoverWrap,
        CIcon,
    },
    props: {
        model: {
            type: Object as PropType<LineItemViewObject | CatalogProductTileViewObject | ProductDetailsModel>,
            required: true,
        },
        disableLineItem: {
            type: Boolean,
            default: false,
        },
        miniBasketMode: {
            type: Boolean,
            default: false,
        },
        productTileMode: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            required: false,
            default: null,
        },
        contentBlockIndex: {
            type: Number,
            required: false,
            default: null,
        },
    },
    setup(props) {
        const route = useRoute();
        const itemInBasketTarget = ref(null);
        const showMaxAllowedQuantity = ref(false);
        const maxAllowed = props.model.maxQuantityPerOrder;

        const quantityInBasket = computed(() => {
            const lineItemInBakset = clientBasket.value.lineItems.find(l => l.sku === props.model.sku);
            return lineItemInBakset?.quantity || 0;
        });

        const quantity = ref<number>(quantityInBasket.value);
        const hasFocus = ref(false);
        const updateQuantityField: Ref<HTMLInputElement | null> = ref(null);

        watch(quantityInBasket, () => {
            quantity.value = quantityInBasket.value;
        });

        function add() {
            quantity.value = 1;
            getAsyncBasketId().then(()=> {
                addProduct(props.model, quantity.value, trackingObject());
            });
        }

        async function increment() {
            if (maxAllowed && quantity.value >= maxAllowed) {
                showMaxAllowedQuantity.value = true;
                return;
            }
            quantity.value += 1;
            quantityFieldAriaLabel = `Antal ${quantity.value}`; 
            await updateLineItem(props.model, quantity.value, trackingObject());
        }

        function decrement() {
            quantity.value -= 1;
            quantityFieldAriaLabel = `Antal ${quantity.value}`; 
            updateLineItem(props.model, quantity.value, trackingObject());
        }

        function updateQuantity() {
            if (updateQuantityField.value) {
                updateQuantityField.value.blur();
            }

            if (maxAllowed && quantity.value > maxAllowed) {
                quantity.value = maxAllowed;
                return;
            }

            if (quantityInBasket.value && quantity.value !== quantityInBasket.value) {
                const q = quantity.value < 0 || quantity.value.toString() === '' ? quantity.value = 0 : quantity.value;
                updateLineItem(props.model, q, trackingObject());
            }
        }

        function remove() {
            quantity.value = 0;
            updateLineItem(props.model, quantity.value, trackingObject());
        }

        watch(quantity, () => {
            showMaxAllowedQuantity.value = maxAllowed && quantity.value > maxAllowed || false;
        });

        function handleMaxAllowedQuantity() {
            showMaxAllowedQuantity.value = false;
            updateQuantity();
        }

        onKeyStroke('Escape', () => {
            handleMaxAllowedQuantity();
        });

        onClickOutside(itemInBasketTarget, () => {
            handleMaxAllowedQuantity();
        });

        function trackingObject(): TrackingObject {
            return {
                productTileMode: props.productTileMode,
                productIndex: props.index,
                contentBlockIndex: props.contentBlockIndex,
            };
        }

        let quantityFieldAriaLabel = `Antal ${quantity.value}`; 

        return {
            clientBasket,
            route,
            increment,
            decrement,
            quantity,
            remove,
            add,
            updateQuantity,
            hasFocus,
            updateQuantityField,
            showMaxAllowedQuantity,
            maxAllowed,
            itemInBasketTarget,
            quantityFieldAriaLabel,
        };
    },
});
</script>

<style scoped>
    .button-inner {
        @apply w-20 m-auto text-24 font-fauli;
    }
    .basket-icon {
        @apply w-22 mr-7 h-auto ml-auto text-white;
    }
    .add-to-basket-background {
        @apply absolute inset-0 -z-1 transition-all duration-300 ease-in-out;
    }
    .add-to-basket-btn {
        @apply h-44 max-w-[18.8rem] w-full text-center relative flex items-center;

        &:hover .add-to-basket-background {
            @apply text-pink-100;
        }
    }

    .icon-hover {
        &:hover {
            animation: icon-hover 500ms ease-in-out infinite;
        }
    }

    .scale-enter-active {
        transition: all .1s ease-out;
    }

    .scale-leave-active {
        transition: all .1s ease-in;
    }

    .scale-enter-from,
    .scale-leave-to {
        transform: scale(0)
    }

    @keyframes icon-hover {
        0% {
            transform: rotate(0);
        }
        25% {
            transform: rotate(-10deg);
        }
        50% {
            transform: rotate(0deg);
        }
        75% {
            transform: rotate(10deg);
        }
    }
</style>
