<template>
    <button ref="target"
            class="overflow-hidden relative py-15 pr-20 md:py-20 md:px-13 lg:p-20"
            :class="{'text-pink-100': showSubMenu}"
            @click="showSubMenu = !showSubMenu;">
        <div class="flex relative z-1 md:hover:text-pink-100 hover-style"
             @mouseenter="setMenuState(true, model.name)">
            <div>{{ model.name }}</div>
            <div class="-mt-3 ml-10 text-20 transition-all"
                 :class="[{'rotate-90': showSubMenu, '-rotate-90': !showSubMenu}]">
                &lt;
            </div>
        </div>
    </button>
    <div ref="submenu"
         class="relative z-1">
        <AnimateValue animation="scroll-down"
                      :duration="[200,200]">
            <div v-if="showSubMenu"
                 class="w-full md:absolute md:inset-0 md:mt-[-6.3rem] md:min-w-[19rem] lg:min-w-[23.5rem]"
                 @mouseleave="setMenuState(false, model.name)">
                <ul role="menu"
                    class="relative px-20 md:py-10 md:px-13 lg:px-20 list-reset">
                    <li class="hidden py-10 opacity-0 transition-all cursor-pointer md:block"
                        :class="{'opacity-100': showSubMenu}"
                        role="menuitem">
                        <div class="flex relative z-1 text-pink-100">
                            <div>{{ model.name }}</div>
                            <div class="-mt-3 ml-10 text-20 transition-all"
                                 :class="[{'rotate-90': showSubMenu, '-rotate-90': !showSubMenu}]">
                                &lt;
                            </div>
                        </div>
                    </li>
                    <li v-for="(item, index) in model.links"
                        :key="index"
                        role="menuitem"
                        class="py-10 last:pb-0 hover:text-pink-100 hover-style">
                        <RouterLink :to="item.url">
                            {{ item.name }}
                        </RouterLink>
                    </li>
                    <CIcon name="submenu-full"
                           original
                           preserveAspectRatio="none"
                           class="hidden absolute inset-0 -z-1 mt-1 w-full h-full md:block"/>
                </ul>
            </div>
        </AnimateValue>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import { onClickOutside, onKeyStroke } from '@vueuse/core';
import AnimateValue from '@/project/animation/AnimateValue.vue';
import { Breakpoint } from '@/project/config/breakpoints.config';
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import { NameAndUrl } from '@/api/content';
import bus from '@/core/bus';

export interface IMenuItemGroup {
    name: string;
    links: NameAndUrl[];
    alias: string;
}

export default defineComponent({
    name: 'NavigationItemGroup',
    components: {
        AnimateValue,
    },
    props: {
        model: {
            type: Object as PropType<IMenuItemGroup>,
            required: true,
        },
    },
    setup() {
        const showSubMenu = ref(false);
        const target = ref(null);
        const isMobileBreakpoint = computed(()=> {
            return useBreakpoints().activeBreakpoint.value === Breakpoint.XS || useBreakpoints().activeBreakpoint.value === Breakpoint.SM;
        });

        function setMenuState(isSelected, name) {
            if (isMobileBreakpoint.value) return;
            showSubMenu.value = isSelected;
            bus.emit('IsShowingSubMenu', { name: name, showSubMenu: isSelected });
        }

        onKeyStroke('Escape', () => {
            showSubMenu.value = false;
        });

        onClickOutside(target, () => showSubMenu.value = false);

        return {
            showSubMenu,
            target,
            setMenuState,
        };
    },
});
</script>

<style scoped>
    .hover-style {
        @apply transition-all duration-150 ease-in-out outline-none;
    }
    .slide-enter-active {
        transition: all .1s ease-out;
    }

    .slide-leave-active {
        transition: all .1s ease-in;
    }

    .slide-enter-from,
    .slide-leave-to {
        transform: scale(0)
    }
</style>
