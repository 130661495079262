<template>
    <component :is="maybeLazyInit"
               class="responsive-image"
               :class="{ 'responsive-image--scrim': scrim }"
               :style="style"
               :offset="offset">
        <picture v-if="imageUrl"
                 ref="picture">
            <source v-if="isWebpWithFallback"
                    key="webpFallbackSrc"
                    type="image/webp"
                    :srcset="srcset"
                    :sizes="sizesFromWidthOnScreen">
            <source :srcset="fallbackSrcset"
                    :sizes="sizesFromWidthOnScreen">
            <img ref="image"
                 v-on-error
                 :alt="alt"
                 :src="src">
        </picture>
        <div v-if="debugEnabled"
             class="debug-enabled">
            {{ currentSrc }}
            {{ sizesFromWidthOnScreen }}
        </div>
    </component>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, Ref, ref, watch } from 'vue';
import { sortBy, keys, map, zipObject } from 'lodash-es';
import { getBreakpoints, getBreakpointDef, useBreakpoints, normalizeBreakpointsStructure } from '../breakpoints/breakpoints.composable';
import logging from '@/core/infrastructure/logging';
import responsiveImageService from './responsive-image.service';
import { BreakpointConfig } from '@/project/config/breakpoints.config';
import { FocalPoint } from '@/api/content/models';
import { isPrerenderRequest } from '@/core/infrastructure/environment';

export default defineComponent({
    name: 'ResponsiveImage',
    props: {
        imageUrl: {
            type: String,
            default: '',
        },
        /*
        Use either widthOnScreen (for vw units) or fixedWidthOnScreen (for px units)
        */
        widthOnScreen: {
            type: [Object, Number] as PropType<BreakpointConfig | number>,
            default: 100,
        },
        fixedWidthOnScreen: {
            type: [Object, Number] as PropType<BreakpointConfig | number>,
            required: false,
            default: undefined,
        },
        lazy: {
            type: Boolean,
            default: true,
            required: false,
        },
        zoom: {
            type: Boolean,
            default: false,
            required: false,
        },
        scrim: {
            type: Boolean,
            default: false,
            required: false,
        },
        offset: {
            type: Number,
            default: 200,
            required: false,
        },
        bgColor: {
            type: String,
            default: 'transparent',
        },
        webpQuality: {
            type: Number,
            default: 80,
            validator: (value: number) => { return value >= 1 && value <= 100; },
        },
        quality: {
            type: Number,
            default: 80,
            validator: (value: number) => { return value >= 1 && value <= 100; },
        },
        alt: {
            type: String,
            default: '',
        },
        focus: {
            type: Object as PropType<FocalPoint>,
            default: undefined,
        },
        mode: {
            type: String,
            default: 'crop',
            validator: (value: string) => { return ['crop', 'pad'].indexOf(value) !== -1; },
        },
        format: {
            type: String,
            default: 'jpg',
            validator: (value: string) => { return ['jpg', 'png', 'webp'].indexOf(value) !== -1; },
        },
        cacheBust: {
            type: [String, Number],
            required: false,
            default: '1',
        },
        debugEnabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        /* Can take a single aspect ratio number, or an object with a number pr. breakpoint. If a 'default' is provided is will be used when no breakpoint is active:
            {
                 xs: 16/9,
                 min-sm,max-md: 4/3
                 default: 1/2
            }
         */
        aspectRatio: {
            type: [Object, Number] as PropType<Record<string, number> | number>,
            default: 16 / 9,
        },
        trimWhiteSpaceThreshold: {
            type: Number,
            default: 0,
        },
        useMaxWidthLimit: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    setup(props) {
        const sizes: Array<number> = responsiveImageService.getSizes();
        const hasSrcsetSupport: Ref<boolean> = ref(true);
        const loadEventFired: Ref<boolean> = ref(false);
        const image: Ref<HTMLElement | null> = ref(null);

        const { isBreakpointActive, activeBreakpoint } = useBreakpoints();

        const activeAspectRatio = ref(0);
        watch([activeBreakpoint, () => props.aspectRatio], () => {
            activeAspectRatio.value = getActiveAspectRatio();
        }, { immediate: true });

        function getActiveAspectRatio() {
            if (typeof props.aspectRatio === 'number') {
                return props.aspectRatio;
            }

            // Else - object with aspect-ratio pr. breakpoint-def. Normalize once.
            const aspectRatiosByNormalizedBreakpoints = normalizeBreakpointsStructure(props.aspectRatio);

            // Find first active breakpoint-def
            const activeBpDef = Object.keys(aspectRatiosByNormalizedBreakpoints).find((bpDef) => isBreakpointActive(bpDef));
            // Use active or default
            const result = activeBpDef ? aspectRatiosByNormalizedBreakpoints[activeBpDef] : (aspectRatiosByNormalizedBreakpoints as any).default;
            if (!result) {
                throw new Error('Provide a breakpoint-definition for all cases or a default');
            }
            return result;
        }

        const absoluteImageUrl = computed(() => {
            return responsiveImageService.getBaseUrl() + props.imageUrl;
        });

        const isWebpSrc = computed(() => {
            return props.imageUrl.indexOf('.webp') > -1;
        });

        const isWebpWithFallback = computed(() => {
            return isWebpSrc.value || props.format === 'webp';
        });

        const normalizedBgColor = computed(() => {
            return props.bgColor.replace('#', '');
        });

        const maybeLazyInit = computed(() => {
            return props.lazy ? 'lazy-init' : 'div';
        });

        const style = computed(() => {
            const padding = `${(100 / activeAspectRatio.value).toFixed(2)}%`;
            const style = { paddingTop: padding, background: `#${normalizedBgColor.value}` };

            return style;
        });

        const heightFromWidth = (width) => {
            return Math.round(width / activeAspectRatio.value);
        };

        const formatImageUrl = (width: number, height: number, format?: string): string => {
            const firstSeparator = !props.imageUrl || props.imageUrl.indexOf('?') === -1 ? '?' : '&';
            const bgColor = normalizedBgColor.value === 'transparent' ? '' : `&bgcolor=${normalizedBgColor.value}`;
            const focus = props.focus ? `&rxy=${props.focus.left},${props.focus.top}` : '';
            return `${absoluteImageUrl.value}${firstSeparator}format=${format || props.format}&width=${width}&height=${height}&quality=${props.quality}&rmode=${props.mode}${bgColor}${focus}`;
        };

        const sortConfigBySize = (config: BreakpointConfig) => {
            const sortedKeys = sortBy(keys(config), (key) => {
                return config[key];
            });

            return zipObject(
                sortedKeys,
                map(sortedKeys, function(key) {
                    return config[key];
                }),
            );
        };

        const sizeForLargestBreakpoint = (config: BreakpointConfig | number): number => {
            if (typeof config === 'number') return config;

            const breakpoints = getBreakpoints();
            const sortedBreakpoints = sortBy(breakpoints, (bp) => {
                return -1 * bp.min;
            }).map(bp => bp.name);

            const configKeys = Object.keys(config);
            for (let i = 0; i < sortedBreakpoints.length; i++) {
                if (configKeys.includes(sortedBreakpoints[i])) {
                    return config[sortedBreakpoints[i]];
                }
            }
            return 100;
        };

        const sizesFromWidthOnScreen = computed(() => {
            const sizeProp = props.fixedWidthOnScreen ? props.fixedWidthOnScreen : props.widthOnScreen;
            const sortedConfigSizes = typeof sizeProp === 'object'
                ? sortConfigBySize(sizeProp)
                : [sizeProp];

            let sizes: string[] = [];
            if (props.fixedWidthOnScreen) {
                // Fixed size (in px) - for instance for product-images
                if (typeof props.fixedWidthOnScreen === 'object') {
                    sizes = map(sortedConfigSizes, (width, screen) => {
                        if (!getBreakpointDef(screen)) {
                            const errorMessage = `ResponsiveImage: Breakpoint ${screen} is not defined for imageUrl ${
                                absoluteImageUrl.value
                            }`;
                            logging.warn(errorMessage);
                        }
                        return `(min-width: ${getBreakpointDef(screen).min}px) ${width}px`;
                    });
                } else {
                    sizes = sizes.concat([`${props.fixedWidthOnScreen}px`]);
                }
            } else {
                // Prepend a max image size in px. when we have maxWidth defined.
                const maxWidth = responsiveImageService.getMaxWidth();
                const maxImageWidth = props.useMaxWidthLimit && maxWidth && maxWidth * sizeForLargestBreakpoint(props.widthOnScreen) / 100;
                sizes = maxImageWidth ? [`(min-width: ${maxWidth}px) ${maxImageWidth}px`] : [];

                if (typeof props.widthOnScreen === 'object') {
                    sizes = sizes.concat(map(sortedConfigSizes, (width, screen) => {
                        if (!getBreakpointDef(screen)) {
                            const errorMessage = `ResponsiveImage: Breakpoint ${screen} is not defined for imageUrl ${
                                absoluteImageUrl.value
                            }`;
                            logging.warn(errorMessage);
                        }
                        return `(min-width: ${getBreakpointDef(screen).min}px) ${width}vw`;
                    }));
                    sizes.push('100vw');
                } else {
                    sizes = sizes.concat([`${props.widthOnScreen}vw`]);
                }
            }
            return sizes.join(',');
        });

        const srcset = computed(() => {
            let srcset = '';
            if (hasSrcsetSupport.value) {
                srcset = sizes
                    .map((width) => {
                        return formatImageUrl(width, heightFromWidth(width)) + ` ${width}w`;
                    })
                    .join(', ');
            }
            return srcset;
        });

        const fallbackSrcset = computed(() => {
            return srcset.value.split('format=webp').join('format=jpg');
        });

        const fallbackSrc = computed(() => {
            const width = sizes[sizes.length - 2]; // second largest
            const format = isWebpWithFallback.value ? 'jpg' : props.format;
            return formatImageUrl(width, heightFromWidth(width), format);
        });

        const src = computed(() => {
            return hasSrcsetSupport.value ? 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7' : fallbackSrc.value;
        });

        onMounted(() => {
            hasSrcsetSupport.value = image.value ? 'sizes' in image.value : true;
        });

        const currentSrc = ref('');
        const picture = ref(null);
        if (props.debugEnabled) {
            setInterval(() => {
                if (picture.value) {
                    currentSrc.value = (picture.value as any as HTMLPictureElement).querySelector('img')?.currentSrc ?? '';
                    currentSrc.value = currentSrc.value.match(/width=\d*&height=\d*/)?.toString() ?? '';
                    console.log(currentSrc.value);
                }
            }, 500);
        }

        return {
            sizes,
            hasSrcsetSupport,
            loadEventFired,
            src,
            style,
            maybeLazyInit,
            sizesFromWidthOnScreen,
            image,
            isWebpWithFallback,
            fallbackSrcset,
            srcset,
            currentSrc,
            picture,
            isPrerenderRequest,
        };
    },
});
</script>

<style scoped>

.responsive-image {
    position: relative;
    overflow: hidden;
    height: 0;
    display: block;
    transition: opacity .3s cubic-bezier(.215,.61,.355,1);
}

.responsive-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.responsive-image--loaded {
    opacity: 1;
}

.responsive-image--scrim:after {
    content: '';
    @apply absolute inset-0 bg-black/20;
}

.debug-enabled {
    @apply absolute inset-0;
    background-color: #ffffff80;
    font-size: 18px;
    word-break: break-all;
}

</style>
