<template>
    <button class="absolute top-2 left-5 opacity-0 focus:opacity-100 focus:z-10 scroll-main-content-btn"
            :aria-label="$translate('Accesibility.GoToMainContent')"
            @click="scrollToMainContent">
        <div class="mx-auto font-fauli text-16 text-white md:text-16">
            {{ $translate('Accesibility.GoToMainContent') }}
        </div>
        <CIcon class="w-full h-full main-content-btn-background"
               name="btn-go-to-main-content"
               fill
               preserveAspectRatio="none"/>
    </button>
    <div class="flex relative z-1 flex-col min-h-full max-page:shadow-page o-container"
         :style="backgroundColorStyle">
        <MainNavigation/>
        <RouterView v-slot="{ Component }">
            <Transition name="fade"
                        mode="out-in"
                        v-on="transitionHooks">
                <PageRenderer id="main-content"
                              :key="key"
                              tabindex="0"
                              :page="Component"/>
            </Transition>
        </RouterView>
        <Footer :class="syncedTransitionClasses"
                class="mt-auto"/>
    </div>
    <ErrorToaster/>
    <DialogHost/>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import ErrorToaster from '@/core/messages/ErrorToaster.vue';
import MainNavigation from '@/project/navigation/main-menu/MainNavigation.vue';
import { useTransitionSync } from '@/project/animation/transitionSyncComposable';
import Footer from '@/project/navigation/footer/Footer.vue';
import { useRoute } from 'vue-router';
import DialogHost from '@/project/dialog/DialogHost.vue';
import { RouterMetaData } from './router';
import PageRenderer from './project/content/PageRenderer.vue';
import useTheme from '@/core/theme/useTheme';
import CIcon from '@/core/layout/icons/CIcon.vue';

export default defineComponent({
    name: 'App',
    components: {
        ErrorToaster,
        MainNavigation,
        Footer,
        DialogHost,
        PageRenderer,
        CIcon,
    },
    setup() {
        const key = ref('');
        const route = useRoute();
        watch(route, route => {
            // Only update key if new route is server-side (thus reinitializing CmsProxyPage for every BE page)
            if ((route.meta as RouterMetaData).isServerPage) {
                key.value = route.path;
            }
        });

        const {
            transitionHooks,
            syncedTransitionClasses,
        } = useTransitionSync('fade');

        const { themeBackgroundColor } = useTheme('#FFFFFF');
        const backgroundColorStyle = computed(() => ({
            backgroundColor: themeBackgroundColor.value,
        }));
        
        function scrollToMainContent() {
            const mainContent = document.getElementById('main-content');
            if (mainContent) {
                mainContent.scrollIntoView({ behavior: 'smooth' });
                mainContent.focus();
            }
        }

        return {
            key,
            transitionHooks,
            syncedTransitionClasses,
            backgroundColorStyle,
            scrollToMainContent,
        };
    },
});
</script>
<style scoped>
    .main-content-btn-background {
        @apply absolute inset-0 -z-1 transition-all duration-300 ease-in-out;
    }

    .scroll-main-content-btn {
    @apply h-44 max-w-[18.8rem] w-full text-center relative flex items-center focus:outline-none;
    }

    .scroll-main-content-btn:focus {
        @apply outline outline-2 outline-offset-2; 
        outline-color: black;
        outline-offset: 2px; 
}
</style>