<template>
    <div class="overflow-hidden fixed inset-0 z-11 bg-black/60" 
         aria-role="alert">
        <TransitionRoot appear
                        :show="true"
                        enter="transition-opacity duration-500"
                        enter-from="opacity-0"
                        enter-to="opacity-100"
                        class="h-auto">
            <div class="flex fixed inset-0 flex-col items-center">
                <div class="relative p-20 my-auto font-fauli text-15 md:mb-0">
                    <div class="max-w-[23.4rem]">
                        <span v-html="$translate('Checkout.Countdown.ReservationExpiredText')"></span>
                        <div class="mt-15">
                            <CallToAction size="large"
                                          variant="PrimaryButton"
                                          color="text-green-100"
                                          class="text-20 text-center"
                                          @click="goBack">
                                {{ $translate('Checkout.Countdown.ButtonText') }}
                            </CallToAction>
                        </div>
                    </div>

                    <CIcon name="overlay-times-up"
                           class="absolute inset-0 -z-1 w-full h-full"
                           original
                           preserveAspectRatio="none"/>
                </div>
                <div class="relative mx-auto mb-auto md:block md:ml-50%">
                    <CIcon name="unik-angry"
                           class="w-300"
                           original/>
                    <p class="text-placement">
                        {{ $translate('Checkout.Countdown.SpeechBubbleText') }}
                    </p>
                </div>
            </div>
        </TransitionRoot>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { TransitionRoot } from '@headlessui/vue';
import { getLastPathBeforeCheckout } from '@/router';
import MiniBasketDialog from '@/project/basket/MiniBasketDialog.vue';
import openDialog from '@/project/dialog/dialog';
import { useRouter } from 'vue-router';
import { trackOverlay } from '../tracking/tracking.service';

export default defineComponent({
    name: 'TimeIsUpOverlay',
    components: {
        TransitionRoot,
    },
    setup() {
        const router = useRouter();

        function goBack() {
            router.replace(getLastPathBeforeCheckout());
            openDialog(MiniBasketDialog, { position: 'right' });
        }
        trackOverlay('Time is up');
        return {
            goBack,
        };
    },
});

</script>

<style scoped>
.text-placement {
    margin-top: 45%;
    margin-left: 3.1rem;
    @apply absolute inset-y-0 font-fauli;
}
</style>
