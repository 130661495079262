<script lang="ts">
import { defineComponent, h, withModifiers, ref } from 'vue';
import contentApi from '@/project/content/api/contentApi';
import { NameAndUrl, SiteSettingsViewModel } from '@/api/content/models';
import openDialog from '../dialog/dialog';
import ContentBlockDialog from '../dialog/ContentPageDialog.vue';
import { trackOverlay } from '../tracking/tracking.service';
import bus from '@/core/bus';
import { DialogClosedKey } from '@/project/dialog/dialog';

export default defineComponent({
    name: 'SmartLabel',
    props: {
        label: {
            required: true,
            type: String,
        },
        wrapperElement: {
            type: String,
            default: 'div',
        },
        linkClass: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        let lastActiveElement;
        async function showDialog(url: string) {
            lastActiveElement = document.activeElement as HTMLElement;
            const page = await contentApi.getPage(url);
            openDialog(ContentBlockDialog, { page });
        }
        
        bus.on(DialogClosedKey, () => {
            if (lastActiveElement) {
                setTimeout(() => {
                    lastActiveElement.focus();
                }, 100);
            }
        });

        const siteSettings = ref<SiteSettingsViewModel | undefined>();
        contentApi.settings.then(settings => siteSettings.value = settings|| undefined);

        // find all placeholders {xxx} in label
        const splitDictionaryLabel = props.label.split(/({.*?})/g);
        return () => h(props.wrapperElement, [
            splitDictionaryLabel.map(value => {
                if (!value.match(/{.*?}/)) {
                    // not a link, return the text as is
                    return value;
                }

                // find a link in SiteSettings that matches the placeholder
                const currentLink = ref<NameAndUrl | undefined>();
                if (siteSettings.value)
                {
                    currentLink.value = siteSettings.value[value.substring(1, value.length - 1)];
                }
                
                // create a link that will display a dialog containing the content of the linked page
                return h('a', { 
                    class: props.linkClass, 
                    innerHTML: currentLink.value?.name,
                    onClick: withModifiers(() => {
                        const url = currentLink.value?.url;
                        if (url) {
                            showDialog(url);
                            trackOverlay(currentLink.value?.name ?? '');
                        }
                    }, ['prevent']),
                });
            }),
        ]);
    },
});
</script>
