<template>
    <div :class="[$attrs.class, {'form-field-text__error': errorMessage}]"
         class="form-field-text">
        <label :for="fieldId"
               class="absolute top-0 left-0 mt-6 ml-16 font-fauli text-10 font-light opacity-0"
               :class="{'opacity-100': !!inputValue}">{{ label }}</label>

        <input :id="fieldId"
               ref="inputField"
               v-autofocus="autofocus"
               v-prohibit-zoom
               v-bind="fieldAttrs"
               :name="name"
               :type="type"
               :value="inputValue"
               class="py-15 px-16 bg-transparent"
               :class="{'pb-10 pt-20': !!inputValue}"
               v-on="validationListeners"
               @focus="isFocused = true"
               @blur="isFocused = false">
        <div v-if="showAsRequired"
             class="flex absolute top-0 right-0 mt-15">
            <CIcon name="required-mark"
                   class="my-auto mr-12 w-10 h-10 text-pink-100"/>
        </div>
        <!-- <InputClear v-if="inputClear" :active="showInputClear" @clear="handleClear"/> -->
        <InputErrorMessage v-if="showErrors"
                           class="mt-5 ml-15 font-fauli text-10"
                           :name="name"/>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, Ref, toRef, watch } from 'vue';
import { useField } from 'vee-validate';

export default defineComponent({
    name: 'InputText',
    inheritAttrs: false,
    props: {
        type: {
            type: String,
            default: 'text',
        },
        value: {
            type: [String, Number],
            default: '',
        },
        // eslint-disable-next-line vue/require-default-prop
        modelValue: {
            type: null,
        },
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        id: {
            type: String,
            required: false,
            default: null,
        },
        autofocus: {
            type: Boolean,
            required: false,
            default: false,
        },
        showErrors: {
            type: Boolean,
            required: false,
            default: true,
        },
        inputClear: {
            type: Boolean,
            required: false,
            default: true,
        },
        fieldDisplayName: {
            type: String,
            required: false,
            default: '',
        },
        validationBehaviour: {
            type: String,
            default: 'eager',
            validate: (value: string): boolean => ['aggressive', 'eager'].includes(value),
        },
        showAsRequired: {
            type: Boolean,
            default: true,
        },
        validationRules: {
            type: String,
            required: false,
            default: '',
        },
    },
    emits: {
        clear: () => true,
        input: () => true,
        'update:modelValue': (evt) => !!evt,
    },
    setup(props, { attrs, emit }) {
        const isFocused: Ref<boolean> = ref(false);

        const isModelBound = computed(() => {
            return 'modelValue' in props;
        });

        const isEmailField = props.name === 'email';
        const debounceTime = 500;

        const {
            value: inputValue,
            errorMessage,
            handleChange,
            handleBlur,
            validate,
            resetField,
        } = useField(props.name, props.validationRules ?? undefined, {
            initialValue: isModelBound.value ? props.modelValue : props.value,
            validateOnValueUpdate: false,
            label: props.fieldDisplayName || props.label,
        });

        const fieldId = computed(() => {
            return props.id || props.name + Math.random();
        });

        const isSearchType = computed(() => {
            return props.type === 'search';
        });

        const fieldAttrs = computed(() => {
            const fieldAttrs = {
                ...attrs,
                class: '',
            };
            return fieldAttrs;
        });

        const showInputClear = computed(() => {
            return isFocused.value && !!inputValue.value;
        });

        const handleClear = () => {
            resetField();
            isModelBound.value && emit('update:modelValue', inputValue.value);
            emit('clear');
        };

        const handleChangeEvent = (evt) => {
            if (evt) {
                isModelBound.value && emit('update:modelValue', evt.target.value);
                emit('input', evt);
                handleChange(evt);
            }
        };
        
        function debounce<T extends(...args: any[]) => void>(fn: T, delay: number) {
            let timeoutId: ReturnType<typeof setTimeout>;
            return (...args: Parameters<T>) => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => fn(...args), delay);
            };
        }

        const handleDebouncedChange = debounce((event: Event) => {
            handleChange(event);
            validate(); 
        }, debounceTime);

        const validationListeners = computed(() => {
            // if no current errors use lazy validation handlers
            const listeners: Record<string, any> = {
                blur: handleBlur,
                change: handleChangeEvent,
                input: isEmailField ? handleDebouncedChange : handleChangeEvent,
            };

            // if aggressive mode or current errors, replace with eager handlers to validate changes immediately
            if (props.validationBehaviour === 'aggressive' || errorMessage.value) {
                listeners.blur = handleChangeEvent;
                listeners.input = handleChangeEvent;
            }

            return listeners;
        });

        if (isModelBound.value) {
            const modelValue = toRef(props, 'modelValue');
            watch(modelValue, newModelValue => {
                if (newModelValue !== inputValue.value) {
                    inputValue.value = newModelValue;
                    validate();
                }
            });
        }

        return {
            validationListeners,
            errorMessage,
            inputValue,
            fieldId,
            isSearchType,
            handleClear,
            showInputClear,
            isFocused,
            fieldAttrs,
        };
    },
});
</script>

<style scoped>
.form-field-text {
    @apply relative;
    & > input {
        background-image: url('@/assets/images/input1.svg');
        background-size: 100% 100%;
        @apply w-full font-fauli text-12;
    }

    &:nth-of-type(even) {
        & > input {
            background-image: url('@/assets/images/input2.svg');
        }
    }

    & > input[type="text"]:disabled {
        @apply opacity-50;
    }
}
.form-field-text__error {
    & > input {
        background-image: url('@/assets/images/input1-error.svg');
    }

    &:nth-of-type(even) {
        & > input {
            background-image: url('@/assets/images/input2-error.svg');
        }
    }
}
</style>
