<template>
    <div>
        <div v-if="model.title && filteredProducts.length"
             class="flex px-20 pt-60 w-full"
             :class="[alignmentStyling]">
            <h2 class="mx-10 h1">
                {{ model.title }}
            </h2>
        </div>
        <div class="py-60 px-10 min-h-[33.6rem] md:px-20 md:min-h-[44.6rem]">
            <ul class="flex flex-wrap gap-y-[4rem] list-reset"
                :class="[alignmentStyling, {'min-h-[0] md:min-h-[0] md:p-0': !filteredProducts.length}]">
                <li v-for="(item, productIndex) in filteredProducts"
                    :key="productIndex"
                    class="item-wrap">
                    <PromotedProductTile v-if="item.promotedTile"
                                         :key="'promoted'+item.product.sku"
                                         :model="item"/>
                    <ProductTile v-else
                                 :key="item.product.sku"
                                 :stock-results="stockResult"
                                 :product="item.product"
                                 :index="productIndex"
                                 :content-block-index="blockIndex"/>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, nextTick, PropType, watch } from 'vue';
import ProductTile from '../product/ProductTile.vue';
import PromotedProductTile from '../product/PromotedProductTile.vue';
import { ProductsBlockViewModel, ContentAlignment, ProductTileViewObject } from '@/api/content/models/';
import { find } from 'lodash-es';
import { StockStatus } from '@/api/stock';
import { useStock } from '../stock/stock.service';
import { trackProductImpression } from '../tracking/tracking.service';
import { useStockStatus } from '../stock/use-stock-status.composable';
import { ProductType } from '@/api/content/models/product-type';

export default defineComponent({
    name: 'ProductsBlock',
    components: { ProductTile, PromotedProductTile },
    props: {
        model: {
            required: true,
            type: Object as PropType<ProductsBlockViewModel>,
        },
        isFirstOnPage: {
            required: true,
            type: Boolean,
        },
        blockIndex: {
            required: true,
            type: Number,
        },
    },
    setup(props) {
        const skus: string[] = props.model.products.map(product => product.product.sku);
        const { stockResult } = useStock(skus);
        const { dataReady } = useStockStatus(stockResult);

        const filteredProducts = computed(()=> {
            return props.model.products.filter(filterProducts);
        });

        function filterProducts(product: ProductTileViewObject) {
            return product.product.productType === ProductType.Product ? !isSoldOut(product.product.sku) : true;
        }

        function isSoldOut(sku) {
            return !!find(stockResult.value, i => i.sku === sku && i.status === StockStatus.SoldOut);
        }

        function getAlignmentStyling(): string {
            switch (props.model.alignment) {
            case ContentAlignment.Left:
                return 'justify-start';
            case ContentAlignment.Center:
                return 'justify-center text-center';
            case ContentAlignment.Right:
                return 'justify-end text-right';
            default:
                return '';
            }
        }

        watch(dataReady, () => {
            if (dataReady.value) {
                nextTick(()=> {
                    trackProductImpression(props.model.products, props.blockIndex, stockResult.value);
                });
            }
        });


        const alignmentStyling: string = getAlignmentStyling();

        return {
            alignmentStyling,
            stockResult,
            filteredProducts,
        };
    },
});
</script>

<style scoped>
 .item-wrap {
     width: calc(50% - 2rem);
     @apply mx-10;
 }

 @screen md {
      .item-wrap {
        width: calc((100% / 3) - 2rem);
        @apply mx-10 min-h-[44.6rem];
    }
 }

@screen xl {
    .item-wrap {
        width: calc(25% - 2rem);
        @apply mx-10;
    }
}
</style>
